import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-profile-list__messages-count bracketed" }

import { inject } from 'vue';
import { useMessagesStore } from '@/stores/messages';
import { toggleMessagesKey } from '@/common/injectionKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListMessagesBtn',
  setup(__props) {

const messagesStore = useMessagesStore();

const toggleMessages = inject(toggleMessagesKey)!;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: "#messages",
    class: "user-profile-list__link flex-center-v",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(toggleMessages) && _unref(toggleMessages)(...args)), ["prevent"]))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("span", null, "Сообщения", -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(messagesStore).count), 1)
  ]))
}
}

})