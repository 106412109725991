import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-profile-list reset-list" }
const _hoisted_2 = { class: "user-profile-list__item user-profile-list__item--profile" }
const _hoisted_3 = { class: "user-profile-list__item user-profile-list__item--messages" }
const _hoisted_4 = { class: "user-profile-list__item" }

import UserListMessagesBtn from './UserListMessagesBtn.vue';
import UserListFavouritesBtn from './UserListFavouritesBtn.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProfileList',
  emits: ["click"],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/profile/user",
        class: "user-profile-list__link flex-center-v",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Профиль ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createVNode(UserListMessagesBtn, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
      })
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(UserListFavouritesBtn, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click')))
      })
    ])
  ]))
}
}

})