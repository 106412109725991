import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import HeaderSearchBar from './HeaderSearchBar.vue';
import { useSearchText } from './composables/useSearchText';
import { useSubmit } from './composables/useSubmit';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSearch',
  setup(__props) {

const { searchText } = useSearchText();
const { submitting, onSubmit } = useSubmit(searchText);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    action: "/search",
    class: "search",
    method: "get",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => _unref(onSubmit)(), ["prevent"]))
  }, [
    _createVNode(HeaderSearchBar, {
      "search-text": _unref(searchText),
      submitting: _unref(submitting),
      onChange: _cache[0] || (_cache[0] = (newSearchText) => (searchText.value = newSearchText)),
      onSubmit: _unref(onSubmit)
    }, null, 8, ["search-text", "submitting", "onSubmit"]),
    _cache[2] || (_cache[2] = _createElementVNode("button", {
      class: "search__btn flex-center",
      type: "submit"
    }, [
      _createElementVNode("svg", {
        class: "icon",
        width: "17",
        height: "16",
        role: "presentation",
        "data-testid": "svg-icon",
        viewBox: "0 0 17 16"
      }, [
        _createElementVNode("path", { d: "M15.787 13.616 12.1 10.393c-.382-.353-.79-.514-1.118-.499a6.1 6.1 0 0 0 1.386-4.25 6.07 6.07 0 0 0-1.867-4.05A5.75 5.75 0 0 0 6.424.001a5.76 5.76 0 0 0-4.012 1.756A6.08 6.08 0 0 0 .703 5.882a6.1 6.1 0 0 0 1.549 4.19 5.78 5.78 0 0 0 3.941 1.918 5.74 5.74 0 0 0 4.135-1.424c-.015.338.142.757.486 1.149l3.136 3.79c.537.613 1.413.665 1.948.115s.485-1.452-.112-2.003zM6.54 10a3.84 3.84 0 0 1-2.752-1.172A4.06 4.06 0 0 1 2.648 6c0-1.06.41-2.078 1.14-2.828A3.84 3.84 0 0 1 6.54 2a3.84 3.84 0 0 1 2.752 1.172c.73.75 1.14 1.767 1.14 2.828s-.41 2.078-1.14 2.828A3.84 3.84 0 0 1 6.54 10" })
      ]),
      _createElementVNode("span", { class: "visually-hidden" }, "Поиск")
    ], -1))
  ], 32))
}
}

})