import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-user-messages__count flex-center"
}

import { useMessagesStore } from '@/stores/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'HUserMessagesBtn',
  setup(__props) {

const messagesStore = useMessagesStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: "#messages",
    class: "h-user-messages",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("svg", {
      class: "icon h-user-messages__img",
      width: "25",
      height: "28",
      role: "presentation",
      "data-testid": "svg-icon",
      viewBox: "0 0 25 28"
    }, [
      _createElementVNode("path", {
        fill: "#1B8DD0",
        d: "M12.5 27.368c.92 0 1.802-.348 2.452-.97a3.24 3.24 0 0 0 1.016-2.341H9.033c0 .878.366 1.72 1.016 2.341s1.532.97 2.452.97z"
      }),
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "m12.5 4.052-1.381.266A7 7 0 0 0 7.131 6.62a6.45 6.45 0 0 0-1.565 4.191c0 1.04-.232 3.638-.796 6.196-.277 1.27-.651 2.593-1.149 3.74h17.76c-.498-1.147-.87-2.468-1.15-3.74-.563-2.558-.796-5.156-.796-6.196a6.45 6.45 0 0 0-1.565-4.19 7 7 0 0 0-3.988-2.3l-1.381-.27v.002zm10.784 16.693c.387.74.834 1.326 1.352 1.656H.366c.518-.33.965-.916 1.351-1.656 1.56-2.98 2.116-8.478 2.116-9.935 0-4.007 2.981-7.351 6.943-8.115-.024-.23.002-.462.078-.682a1.6 1.6 0 0 1 .361-.594 1.75 1.75 0 0 1 .582-.402 1.8 1.8 0 0 1 1.407 0c.221.094.42.23.582.402a1.6 1.6 0 0 1 .44 1.276 8.75 8.75 0 0 1 4.986 2.876 8.06 8.06 0 0 1 1.957 5.24c0 1.457.555 6.954 2.115 9.934"
      })
    ], -1)),
    (_unref(messagesStore).count)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(messagesStore).count), 1))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "h-user-messages__text" }, "Сообщения", -1))
  ]))
}
}

})