import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "favourites"
}
const _hoisted_2 = {
  key: 0,
  class: "favourites__empty-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VFavouritesSkeleton = _resolveComponent("VFavouritesSkeleton")!
  const _component_VFavouritesRow = _resolveComponent("VFavouritesRow")!
  const _component_VTable = _resolveComponent("VTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_VFavouritesSkeleton, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.favouritesStore.books || !_ctx.favouritesStore.books.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Нет избранных книг "))
            : (_openBlock(), _createBlock(_component_VTable, {
                key: 1,
                class: "favourites__table"
              }, {
                thead: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("th", { class: "v-table__col v-table__col--book" }, "Товар", -1),
                  _createElementVNode("th", { class: "v-table__col" }, "Цена", -1),
                  _createElementVNode("th", { class: "v-table__col" }, null, -1)
                ])),
                tbody: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favouritesStore.books, (book) => {
                    return (_openBlock(), _createBlock(_component_VFavouritesRow, {
                      key: book.id,
                      book: book
                    }, null, 8, ["book"]))
                  }), 128))
                ]),
                _: 1
              }))
        ]))
  ]))
}