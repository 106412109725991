import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-footer" }
const _hoisted_2 = { class: "main-footer__inner container" }
const _hoisted_3 = { class: "main-footer__section main-footer__section--social" }
const _hoisted_4 = { class: "main-footer__wrap" }
const _hoisted_5 = { class: "main-footer__section" }
const _hoisted_6 = { class: "main-footer__list reset-list" }
const _hoisted_7 = { class: "main-footer__list-item" }
const _hoisted_8 = { class: "main-footer__list-item" }

import SocialList from './SocialList.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TheFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "main-footer__social-caption" }, "Мы в соцсетях:", -1)),
        _createVNode(SocialList, { class: "main-footer__social-list reset-list" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[6] || (_cache[6] = _createStaticVNode("<section class=\"main-footer__section\"><p class=\"main-footer__caption\">Наша компания</p><ul class=\"main-footer__list reset-list\"><li class=\"main-footer__list-item\"><a href=\"/\">О компании</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Контакты</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Служба поддержки</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Возврат</a></li></ul></section>", 1)),
        _createElementVNode("section", _hoisted_5, [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "main-footer__caption" }, "Каталог", -1)),
          _createElementVNode("ul", _hoisted_6, [
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/catalog" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Все книги")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, { to: "/catalog/bestsellers" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Бестселлеры")
                ])),
                _: 1
              })
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("li", { class: "main-footer__list-item" }, [
              _createElementVNode("a", { href: "/" }, "Скоро в продаже")
            ], -1)),
            _cache[4] || (_cache[4] = _createElementVNode("li", { class: "main-footer__list-item" }, [
              _createElementVNode("a", { href: "/" }, "Популярные авторы")
            ], -1))
          ])
        ]),
        _cache[7] || (_cache[7] = _createStaticVNode("<section class=\"main-footer__section main-footer__section--advanced\"><p class=\"main-footer__caption\">Интересно</p><ul class=\"main-footer__list reset-list\"><li class=\"main-footer__list-item\"><a href=\"/\">Книжные обзоры</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Подборки читателей</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Рецензии читателей</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Интервью с авторами</a></li></ul></section><section class=\"main-footer__section\"><p class=\"main-footer__caption\">Помощь</p><ul class=\"main-footer__list reset-list\"><li class=\"main-footer__list-item\"><a href=\"/\">Оплата</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Поддержка</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Напишите нам</a></li><li class=\"main-footer__list-item\"><a href=\"/\">Как сделать заказ</a></li></ul></section>", 2))
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("section", { class: "main-footer__section main-footer__section--copyright" }, [
        _createElementVNode("p", { class: "main-footer__copyright" }, "© ООО «РидРес»")
      ], -1))
    ])
  ]))
}
}

})