import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "social-list flex-center-h" }
const _hoisted_2 = { class: "social-list__item" }
const _hoisted_3 = {
  href: "/",
  class: "social-list__btn flex-center"
}
const _hoisted_4 = { class: "social-list__item" }
const _hoisted_5 = {
  href: "/",
  class: "social-list__btn flex-center"
}
const _hoisted_6 = { class: "social-list__item" }
const _hoisted_7 = {
  href: "/",
  class: "social-list__btn flex-center"
}
const _hoisted_8 = { class: "social-list__item" }
const _hoisted_9 = {
  href: "/",
  class: "social-list__btn flex-center"
}

import SvgIcon from '@/components/SvgIcon.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialList',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "visually-hidden" }, "ВКонтакте", -1)),
        _createVNode(SvgIcon, {
          icon: "social-vk",
          width: "21",
          height: "12"
        })
      ])
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("a", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "visually-hidden" }, "Facebook", -1)),
        _createVNode(SvgIcon, {
          icon: "social-fb",
          width: "9",
          height: "18"
        })
      ])
    ]),
    _createElementVNode("li", _hoisted_6, [
      _createElementVNode("a", _hoisted_7, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "visually-hidden" }, "Instagram", -1)),
        _createVNode(SvgIcon, {
          icon: "social-inst",
          width: "22",
          height: "22"
        })
      ])
    ]),
    _createElementVNode("li", _hoisted_8, [
      _createElementVNode("a", _hoisted_9, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "visually-hidden" }, "Youtube", -1)),
        _createVNode(SvgIcon, {
          icon: "social-yt",
          width: "24",
          height: "16"
        })
      ])
    ])
  ]))
}
}

})