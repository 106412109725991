import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "h-login-link",
    to: "/auth/login"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("svg", {
        class: "icon h-login-link__img",
        width: "21",
        height: "21",
        role: "presentation",
        "data-testid": "svg-icon",
        viewBox: "0 0 21 21"
      }, [
        _createElementVNode("path", { d: "M10.705 13.828a1.086 1.086 0 0 0 1.536 1.536l4.096-4.096a1.086 1.086 0 0 0 0-1.536l-4.096-4.096a1.086 1.086 0 0 0-1.536 1.536l2.242 2.242H1.087a1.086 1.086 0 1 0 0 2.172h11.86z" }),
        _createElementVNode("path", { d: "M19.19 0H9.051a1.81 1.81 0 0 0-1.81 1.81v1.45a1.086 1.086 0 1 0 2.172 0V2.172h9.413v16.656H9.414V17.74a1.086 1.086 0 1 0-2.173 0v1.449A1.81 1.81 0 0 0 9.051 21H19.19A1.81 1.81 0 0 0 21 19.19V1.81A1.81 1.81 0 0 0 19.19 0" })
      ], -1),
      _createElementVNode("span", { class: "visually-hidden" }, "Войти", -1)
    ])),
    _: 1
  }))
}