import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-table__row favourites__row" }
const _hoisted_2 = { class: "v-table__col favourites__col" }
const _hoisted_3 = { class: "v-table__col v-table__col--widest v-table__col--hide-mobile" }
const _hoisted_4 = { class: "v-table__col favourites__col favourites__col--delete" }

import OrderBookSkeleton from '@/components/OrderBook/OrderBookSkeleton.vue';
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'VFavouritesRowSkeleton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createVNode(OrderBookSkeleton, {
        "with-price": "",
        small: "",
        class: "v-table__book"
      })
    ]),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(SkeletonLoader, {
        width: "118",
        height: "20"
      })
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(SkeletonLoader, {
        width: "21",
        height: "21",
        class: "v-table__delete"
      })
    ])
  ]))
}
}

})