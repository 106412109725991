import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, setBlockTracking as _setBlockTracking, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@img/book-placeholder.svg'


const _hoisted_1 = ["src", "srcset"]

import { ref } from 'vue';
import { ImageSlot } from '@/constants/ImageSlot';
import {
  ImageSize,
  ImageFormat,
  type ImgAttributes,
  type ImageScale,
} from './types';

interface Props {
  width?: number | string;
  src?: string;
  alt?: string;
  title?: string;
  slotType: keyof typeof ImageSlot;
  lazyLoading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookImg',
  props: {
    width: { default: () => (undefined) },
    src: { default: '' },
    alt: { default: () => (undefined) },
    title: { default: () => (undefined) },
    slotType: {},
    lazyLoading: { type: Boolean, default: false }
  },
  emits: ["loaded", "error"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const noImg = ref(!__props.src);
const loaded = ref(false);

const imgAttrs = {
  width: __props.width ? +__props.width : undefined,
  alt: __props.alt,
  title: __props.title,
  loading: __props.lazyLoading ? 'lazy' : undefined,
} as ImgAttributes;

const resolveImgParams = (
  format: ImageFormat,
  size: keyof typeof ImageSize,
  scale?: ImageScale,
) => {
  const imgParams = [format, ImageSlot[__props.slotType], ImageSize[size]];

  if (scale && scale > 1) {
    imgParams.push(scale);
  }

  return imgParams.join('_');
};

const imgUrl = (
  size: keyof typeof ImageSize,
  format: ImageFormat,
  scale?: ImageScale,
) => {
  const imgUrlHead = __props.src.replace(/^\/?img\//, '/image/');

  const params = resolveImgParams(format, size, scale);

  return `${imgUrlHead}@${params}`;
};

const srcSet = (size: keyof typeof ImageSize, format: ImageFormat) => {
  return `
    ${imgUrl(size, format, 1)} 1x,
    ${imgUrl(size, format, 2)} 2x,
    ${imgUrl(size, format, 3)} 3x
  `;
};

const onLoaded = async (evt: Event) => {
  loaded.value = true;
  emit('loaded', evt.target as HTMLImageElement);
};

const onError = () => {
  noImg.value = true;
  emit('error');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("picture", null, [
    (noImg.value)
      ? (_openBlock(), _createElementBlock("img", _mergeProps({ key: 0 }, imgAttrs, {
          src: _imports_0,
          class: { loaded: loaded.value },
          onLoad: onLoaded
        }), null, 16))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[0] || (
            _setBlockTracking(-1),
            (_cache[0] = _createElementVNode("source", {
              type: "image/webp",
              srcset: srcSet('large', _unref(ImageFormat).WEBP),
              media: "(min-width: 1200px)"
            }, null, 8, ["srcset"])).cacheIndex = 0,
            _setBlockTracking(1),
            _cache[0]
          ),
          _cache[1] || (
            _setBlockTracking(-1),
            (_cache[1] = _createElementVNode("source", {
              srcset: srcSet('large', _unref(ImageFormat).JPG),
              media: "(min-width: 1200px)"
            }, null, 8, ["srcset"])).cacheIndex = 1,
            _setBlockTracking(1),
            _cache[1]
          ),
          _cache[2] || (
            _setBlockTracking(-1),
            (_cache[2] = _createElementVNode("source", {
              type: "image/webp",
              srcset: srcSet('medium', _unref(ImageFormat).WEBP),
              media: "(min-width: 768px)"
            }, null, 8, ["srcset"])).cacheIndex = 2,
            _setBlockTracking(1),
            _cache[2]
          ),
          _cache[3] || (
            _setBlockTracking(-1),
            (_cache[3] = _createElementVNode("source", {
              srcset: srcSet('medium', _unref(ImageFormat).JPG),
              media: "(min-width: 768px)"
            }, null, 8, ["srcset"])).cacheIndex = 3,
            _setBlockTracking(1),
            _cache[3]
          ),
          _cache[4] || (
            _setBlockTracking(-1),
            (_cache[4] = _createElementVNode("source", {
              type: "image/webp",
              srcset: srcSet('small', _unref(ImageFormat).WEBP)
            }, null, 8, ["srcset"])).cacheIndex = 4,
            _setBlockTracking(1),
            _cache[4]
          ),
          _createElementVNode("img", _mergeProps(imgAttrs, {
            src: imgUrl('small', _unref(ImageFormat).JPG),
            srcset: srcSet('small', _unref(ImageFormat).JPG),
            class: { loaded: loaded.value },
            onLoad: onLoaded,
            onError: onError
          }), null, 16, _hoisted_1)
        ], 64))
  ]))
}
}

})