import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search__suggestion" }
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = { class: "search__img-wrap flex-center" }
const _hoisted_4 = ["innerHTML"]

import { computed } from 'vue';
import { useSanitize } from '@/composables/useSanitize';
import type { Suggestion } from './types';
import HeaderSuggestionIcon from './HeaderSuggestionIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSuggestion',
  props: {
    search: {},
    suggestion: {},
    isActive: { type: Boolean, default: false }
  },
  emits: ["click"],
  setup(__props: any) {

const { sanitizeHtml } = useSanitize();





const linkHtmlTitle = computed(() => {
  const title = __props.suggestion.title.replace(
    new RegExp(`(${__props.search})`, 'gi'),
    '<b>$1</b>',
  );

  return sanitizeHtml(title);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.suggestion.url,
      title: _ctx.suggestion.title,
      class: _normalizeClass(["search__link flex-center-v", { 'search__link--active': _ctx.isActive }]),
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click')), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(HeaderSuggestionIcon, { suggestion: _ctx.suggestion }, null, 8, ["suggestion"])
      ]),
      _createElementVNode("span", { innerHTML: linkHtmlTitle.value }, null, 8, _hoisted_4)
    ], 10, _hoisted_2)
  ]))
}
}

})