import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "onClick"]
const _hoisted_2 = {
  key: 0,
  class: "user-login-link__counter"
}

import { computed, inject } from 'vue';
import { useMessagesStore } from '@/stores/messages';
import { useUserStore } from '@/stores/user';
import { type ViewSize, viewSizeKey } from '@/plugins/window-size';


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UserLoginLink',
  props: {
    showProfileMenu: { type: Boolean }
  },
  emits: ["click", "toggleProfileMenu"],
  setup(__props: any, { emit: __emit }) {

const { isLaptopView } = inject(viewSizeKey) as ViewSize;





const emit = __emit;

const userStore = useUserStore();
const messagesStore = useMessagesStore();

const userName = computed(() => userStore.user?.name || 'Войти');

const isLoggedIn = computed(() => !!userStore.user);

const linkUrl = computed(() => (isLoggedIn.value ? '/profile' : '/auth/login'));

const toggleProfileMenu = (event: Event, navigate: () => unknown) => {
  event.preventDefault();

  if (!isLaptopView() || !isLoggedIn.value) {
    navigate();
  } else {
    emit('toggleProfileMenu');
  }

  emit('click');
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: linkUrl.value,
    custom: ""
  }, {
    default: _withCtx(({ navigate, href }) => [
      _createElementVNode("a", {
        href: href,
        class: _normalizeClass(["user-login-link", [_ctx.$attrs.class, { 'user-login-link--active': _ctx.showProfileMenu }]]),
        onClick: ($event: any) => (toggleProfileMenu($event, navigate))
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("svg", {
          class: "icon user-login-link__avatar user-login-link__avatar--no-photo",
          width: "36",
          height: "36",
          role: "presentation",
          "data-testid": "svg-icon",
          viewBox: "0 0 36 36"
        }, [
          _createElementVNode("path", { d: "M34.588 29.379a16.9 16.9 0 0 0-3.859-5.405 18.1 18.1 0 0 0-5.72-3.645c-.02-.009-.039-.013-.058-.023 2.982-2.035 4.921-5.35 4.921-9.09C29.872 5.02 24.558 0 18 0 11.441 0 6.128 5.02 6.128 11.216c0 3.74 1.939 7.055 4.921 9.095-.02.01-.038.014-.057.023a17.9 17.9 0 0 0-5.721 3.645 17 17 0 0 0-3.859 5.404A16 16 0 0 0 0 35.63a.34.34 0 0 0 .109.262.4.4 0 0 0 .274.109h2.872a.37.37 0 0 0 .383-.353c.096-3.491 1.58-6.761 4.204-9.24 2.714-2.564 6.319-3.975 10.158-3.975 3.84 0 7.444 1.411 10.158 3.976 2.624 2.478 4.108 5.748 4.204 9.24.004.198.172.352.383.352h2.872a.4.4 0 0 0 .274-.11.36.36 0 0 0 .109-.26 16.1 16.1 0 0 0-1.412-6.251zM18 18.995c-2.197 0-4.265-.81-5.821-2.28s-2.413-3.423-2.413-5.499.857-4.03 2.413-5.5S15.803 3.437 18 3.437s4.265.81 5.821 2.28 2.413 3.423 2.413 5.5c0 2.075-.857 4.029-2.413 5.499S20.197 18.995 18 18.995" })
        ], -1)),
        _createElementVNode("span", {
          class: _normalizeClass(["user-login-link__name", { 'user-login-link__name--guest': !isLoggedIn.value }])
        }, _toDisplayString(userName.value), 3),
        (isLoggedIn.value && _unref(messagesStore).count)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(messagesStore).count), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})