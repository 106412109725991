import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-cart-btn__counter bracketed" }
const _hoisted_2 = { class: "user-cart-btn__sum" }

import { computed } from 'vue';
import { useCartStore } from '@/stores/cart';
import { useCurrency } from '@/composables/useCurrency';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserCartBtn',
  emits: ["click"],
  setup(__props) {



const cartStore = useCartStore();

const currency = useCurrency();

const totalDiscountedSum = computed(() =>
  currency(cartStore.totalDiscountedSum),
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "user-cart-btn",
    to: "/cart",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("svg", {
        class: "icon user-cart-btn__img",
        width: "26",
        height: "21",
        role: "presentation",
        "data-testid": "svg-icon",
        viewBox: "0 0 26 21"
      }, [
        _createElementVNode("path", { d: "M1.37.6a1.04 1.04 0 0 0-.728.291 1 1 0 0 0-.3.706c0 .265.108.519.3.706.193.187.454.293.726.293h2.28l2.696 10.485c.23.889 1.048 1.498 1.99 1.498h12.809c.927 0 1.715-.599 1.958-1.468l2.663-9.517h-2.151l-2.471 8.988H8.333L5.64 2.097a1.98 1.98 0 0 0-.728-1.083A2.08 2.08 0 0 0 3.65.599H1.37zm18.489 13.98c-1.69 0-3.082 1.353-3.082 2.996s1.392 2.996 3.082 2.996 3.081-1.353 3.081-2.996-1.392-2.996-3.081-2.996m-9.245 0c-1.69 0-3.082 1.353-3.082 2.996s1.392 2.996 3.082 2.996 3.081-1.353 3.081-2.996-1.391-2.996-3.081-2.996M13.695.6v4.992h-3.081l4.109 3.995 4.108-3.995H15.75V.6zm-3.081 15.978c.58 0 1.027.435 1.027.998s-.448.999-1.027.999c-.58 0-1.027-.436-1.027-.999s.448-.998 1.027-.998m9.245 0c.579 0 1.027.435 1.027.998s-.448.999-1.027.999c-.58 0-1.028-.436-1.028-.999s.448-.998 1.028-.998" })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "user-cart-btn__text" }, "Корзина", -1)),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(cartStore).totalBooksCount), 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(totalDiscountedSum.value), 1)
    ]),
    _: 1
  }))
}
}

})