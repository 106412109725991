import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { class: "main-header__nav main-nav container flex-center-v" }
const _hoisted_3 = { class: "main-nav__btn-wrap" }

import { ref, computed, watch, provide } from 'vue';
import VModal from '@/components/VModal.vue';
import HeaderUserList from './components/UserList/HeaderUserList.vue';
import HeaderNavList from './components/HeaderNavList/HeaderNavList.vue';
import HeaderSearch from './components/HeaderSearch/HeaderSearch.vue';
import SiteLogo from './components/SiteLogo.vue';
import HUserMessagesBtn from './components/HUserMessagesBtn.vue';
import HLoginLink from './components/HLoginLink.vue';
import VMessages from '@/components/Messages/VMessages.vue';
import VFavourites from '@/components/VFavourites/VFavourites.vue';
import { disableScrolling, enableScrolling } from '@/common/functions';
import { useUserStore } from '@/stores/user';
import { isMobileView } from '@/plugins/window-size';
import { useRoute } from 'vue-router';
import { showFavouritesKey, toggleMessagesKey } from '@/common/injectionKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'TheHeader',
  setup(__props) {

const route = useRoute();

const userStore = useUserStore();

const isShowMenu = ref(false);
const isShowMessages = ref(false);
const isShowFavourites = ref(false);

const isMobileGuestLink = computed(
  () => !userStore.isLoggedIn && isMobileView(),
);

const toggleMenu = () => {
  isShowMenu.value = !isShowMenu.value;
  isShowMessages.value = false;
};

const toggleMessages = () => {
  isShowMessages.value = !isShowMessages.value;
  isShowMenu.value = false;
};

const showFavourites = () => {
  disableScrolling();
  isShowFavourites.value = true;
  isShowMessages.value = false;
  isShowMenu.value = false;
};

const hideFavourites = () => {
  isShowFavourites.value = false;
  enableScrolling();
};

watch(route, () => {
  isShowMenu.value = false;
  isShowMessages.value = false;
  hideFavourites();
});

provide(toggleMessagesKey, toggleMessages);
provide(showFavouritesKey, showFavourites);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["main-nav__list-wrap", { 'main-nav__list-wrap--expanded': isShowMenu.value }])
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(["main-nav__btn", {
              'main-nav__btn--burger btn-burger': !isShowMenu.value,
              'main-nav__btn--close btn-close': isShowMenu.value,
            }]),
            onClick: _withModifiers(toggleMenu, ["prevent"])
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "visually-hidden" }, "Открыть/Закрыть меню", -1)
          ]), 2),
          _createVNode(HeaderUserList, {
            "show-menu": isShowMenu.value,
            class: "main-nav__user-list",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowMenu.value = false))
          }, null, 8, ["show-menu"]),
          _createVNode(HeaderNavList, {
            "is-show": isShowMenu.value,
            class: "main-nav__list",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isShowMenu.value = false))
          }, null, 8, ["is-show"])
        ], 2)
      ]),
      _createVNode(SiteLogo, { class: "main-nav__logo" }),
      (isMobileGuestLink.value)
        ? (_openBlock(), _createBlock(HLoginLink, { key: 0 }))
        : (_openBlock(), _createBlock(HUserMessagesBtn, {
            key: 1,
            onClick: toggleMessages
          })),
      _createVNode(HeaderSearch, { class: "main-nav__search" })
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "messages" }, {
        default: _withCtx(() => [
          (isShowMessages.value)
            ? (_openBlock(), _createBlock(VMessages, {
                key: 0,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (isShowMessages.value = false))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])),
    (isShowFavourites.value)
      ? (_openBlock(), _createBlock(VModal, {
          key: 0,
          header: "Избранное",
          onClose: hideFavourites
        }, {
          default: _withCtx(() => [
            _createVNode(VFavourites)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})