import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-profile-list__wish-count bracketed" }

import { inject } from 'vue';
import { useFavouritesStore } from '@/stores/favourites';
import { showFavouritesKey } from '@/common/injectionKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListFavouritesBtn',
  setup(__props) {

const favouritesStore = useFavouritesStore();

const showFavourites = inject(showFavouritesKey)!;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: "#favourites_btn",
    class: "user-profile-list__link flex-center-v",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(showFavourites) && _unref(showFavourites)(...args)), ["prevent"]))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("svg", {
      class: "icon user-profile-list__img",
      width: "25",
      height: "21",
      role: "presentation",
      "data-testid": "svg-icon",
      viewBox: "0 0 25 21"
    }, [
      _createElementVNode("path", { d: "m12.5 21-.441-.354C2.574 13.223 0 10.607 0 6.364 0 2.828 2.941 0 6.618 0c3.014 0 4.705 1.626 5.882 2.899C13.677 1.626 15.368 0 18.382 0 22.06 0 25 2.828 25 6.364c0 4.242-2.573 6.858-12.059 14.282zM6.618 1.414c-2.868 0-5.147 2.192-5.147 4.95 0 3.606 2.353 6.01 11.029 12.798 8.677-6.788 11.03-9.192 11.03-12.798 0-2.758-2.28-4.95-5.148-4.95-2.573 0-3.97 1.485-5.073 2.687l-.809.92-.809-.92c-1.103-1.202-2.5-2.687-5.073-2.687" })
    ], -1)),
    _cache[2] || (_cache[2] = _createElementVNode("span", null, "Избранное", -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(favouritesStore).count), 1)
  ]))
}
}

})