import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "guest-login-link__img-wrap flex-center-h" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "guest-login-link",
    to: "/auth/login"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          icon: "login",
          class: "guest-login-link__img",
          width: "21",
          height: "21"
        })
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "user-login-link__text" }, "Войти", -1))
    ]),
    _: 1
  }))
}