import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-table__row favourites__row" }
const _hoisted_2 = { class: "v-table__col favourites__col" }
const _hoisted_3 = { class: "v-table__col v-table__col--widest v-table__col--hide-mobile" }
const _hoisted_4 = { class: "v-table__col favourites__col favourites__col--delete" }
const _hoisted_5 = ["disabled"]

import { ref, computed, inject } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import OrderBook from '@/components/OrderBook/OrderBook.vue';
import type { Book } from '@/pages/book/types';
import { useCurrency } from '@/composables/useCurrency';
import { useFavouritesStore } from '@/stores/favourites';
import { type Toast, toastKey } from '@/plugins/vue-toast-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'VFavouritesRow',
  props: {
    book: {}
  },
  setup(__props: any) {

const { errorToast } = inject(toastKey) as Toast;

const favouritesStore = useFavouritesStore();

const currency = useCurrency();



const removing = ref(false);

const priceRange = computed(() => {
  if (!__props.book.variations || !__props.book.variations.length) {
    return 'Нет в продаже';
  }

  const minPrice = Math.min(
    ...__props.book.variations.map((variation) => variation.price.price),
    99999,
  );
  const maxPrice = Math.max(
    ...__props.book.variations.map((variation) => variation.price.price),
    0,
  );

  if (minPrice !== maxPrice) {
    return `${currency(minPrice)} - ${currency(maxPrice)}`;
  }

  return currency(minPrice);
});

const remove = async () => {
  removing.value = true;

  try {
    await favouritesStore.removeFromFavourites(__props.book.id);
  } catch {
    errorToast('Не удалось удалить из избранного');
  } finally {
    removing.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createVNode(OrderBook, {
        book: _ctx.book,
        "price-range": priceRange.value,
        small: "",
        "img-slot-type": "favourite",
        class: "v-table__book"
      }, null, 8, ["book", "price-range"])
    ]),
    _createElementVNode("td", _hoisted_3, _toDisplayString(priceRange.value), 1),
    _createElementVNode("td", _hoisted_4, [
      _createElementVNode("button", {
        class: _normalizeClass(["v-table__delete", {
          'v-table__delete--disabled': removing.value,
        }]),
        disabled: removing.value,
        title: "Удалить",
        onClick: remove
      }, [
        _createVNode(SvgIcon, {
          class: "v-table__delete-icon",
          icon: "cross",
          width: "21",
          height: "21"
        }),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "visually-hidden" }, "Удалить", -1))
      ], 10, _hoisted_5)
    ])
  ]))
}
}

})