import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "order-book__cover-wrap flex-center" }
const _hoisted_2 = { class: "order-book__wrap" }
const _hoisted_3 = { class: "order-book__title" }
const _hoisted_4 = { class: "order-book__authors" }
const _hoisted_5 = { class: "order-book__icons" }

import { ref } from 'vue';
import truncate from 'lodash/truncate';
import type { VariationType } from '@/components/types/book_types';
import BookImg from '@/components/BookImg/BookImg.vue';
import BookAuthorLinks from '@/components/BookAuthorLinks.vue';
import type { ImageSlot } from '@/constants/ImageSlot';
import { bookFullName } from '@/common/functions';
import SvgIcon from '@/components/SvgIcon.vue';
import { iconParams, type IconParams } from './iconParams';

interface OrderBookPartialInterface {
  name: string;
  variations: {
    type: VariationType;
  }[];
  authors: {
    id: number;
    name: string;
  }[];
  url: string;
  imgUrl: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderBook',
  props: {
    book: {},
    priceRange: { default: null },
    small: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    imgSlotType: {}
  },
  setup(__props: any) {



const noImg = ref(!__props.book.imgUrl);

const fullName = bookFullName(__props.book);

const truncatedName = truncate(__props.book.name, {
  length: 60,
});

const getIconParams = (variationType: VariationType): IconParams =>
  iconParams[variationType];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["order-book", {
      'order-book--small': _ctx.small,
      'order-book--medium': _ctx.medium,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: _ctx.book.url,
        class: _normalizeClass(["order-book__cover book-cover flex-center-v", { 'book-cover--no-img': noImg.value }])
      }, {
        default: _withCtx(() => [
          _createVNode(BookImg, {
            width: "107",
            src: _ctx.book.imgUrl,
            alt: _unref(fullName),
            "slot-type": _ctx.imgSlotType,
            class: "order-book__cover-img",
            onError: _cache[0] || (_cache[0] = ($event: any) => (noImg.value = true))
          }, null, 8, ["src", "alt", "slot-type"])
        ]),
        _: 1
      }, 8, ["to", "class"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: _ctx.book.url
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(truncatedName)), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createVNode(BookAuthorLinks, {
          book: _ctx.book,
          "link-class": "order-book__author"
        }, null, 8, ["book"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.book.variations, (variation) => {
          return (_openBlock(), _createBlock(SvgIcon, _mergeProps({
            key: variation.type,
            class: "order-book__icon",
            ref_for: true
          }, getIconParams(variation.type)), null, 16))
        }), 128))
      ]),
      (_ctx.priceRange)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.book.url,
            class: "order-book__price"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.priceRange), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})