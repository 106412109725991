import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "favourites favourites-skeleton" }
const _hoisted_2 = { class: "v-table__col v-table__col--book" }
const _hoisted_3 = { class: "v-table__col" }

import VTable from '@/components/VTable.vue';
import VFavouritesRowSkeleton from './VFavouritesRowSkeleton.vue';
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'VFavouritesSkeleton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(VTable, { class: "favourites__table" }, {
      thead: _withCtx(() => [
        _createElementVNode("th", _hoisted_2, [
          _createVNode(SkeletonLoader, {
            width: "47",
            height: "23"
          })
        ]),
        _createElementVNode("th", _hoisted_3, [
          _createVNode(SkeletonLoader, {
            width: "42",
            height: "23",
            class: "favourites-skeleton__price-header"
          })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("th", { class: "v-table__col" }, null, -1))
      ]),
      tbody: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
          return _createVNode(VFavouritesRowSkeleton, { key: i })
        }), 64))
      ]),
      _: 1
    })
  ]))
}
}

})