import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import HeaderNavItem from './HeaderNavItem.vue';
import { navListItems } from './items';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderNavList',
  props: {
    isShow: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["nav-list reset-list", { 'nav-list--hidden': !_ctx.isShow }])
  }, [
    _cache[0] || (
      _setBlockTracking(-1),
      (_cache[0] = _createVNode(HeaderNavItem, {
        url: "/",
        name: "Главная",
        class: "nav-list__item--home"
      })).cacheIndex = 0,
      _setBlockTracking(1),
      _cache[0]
    ),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navListItems), (item) => {
      return (_openBlock(), _createBlock(HeaderNavItem, _mergeProps({
        key: item.name,
        ref_for: true
      }, item, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click')))
      }), null, 16))
    }), 128))
  ], 2))
}
}

})