import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["width", "height", "role", "aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["href"]


let uuidCounter = 0;

export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
    icon: {},
    spin: { type: Boolean, default: false },
    width: { default: () => (undefined) },
    height: { default: () => (undefined) },
    alt: { default: null }
  },
  setup(__props: any) {



const uuid = ++uuidCounter;

const role = __props.alt ? 'img' : 'presentation';

const titleId = __props.alt ? `title-${uuid}` : undefined;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["icon", { 'icon-spin': _ctx.spin }]),
    width: _ctx.width,
    height: _ctx.height,
    role: _unref(role),
    "aria-labelledby": _unref(titleId),
    "data-testid": "svg-icon"
  }, [
    (_ctx.alt)
      ? (_openBlock(), _createElementBlock("title", {
          key: 0,
          id: _unref(titleId)
        }, _toDisplayString(_ctx.alt), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("use", {
      href: `#${_ctx.icon}`
    }, null, 8, _hoisted_3)
  ], 10, _hoisted_1))
}
}

})