import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'SiteLogo',
  props: {
    isAuth: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = _createVNode(_component_router_link, {
      to: "/",
      class: "site-logo"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(["site-logo__img", { 'site-logo__img--auth': _ctx.isAuth }]),
          role: "img",
          "aria-labelledby": "site-logo",
          "data-testid": "svg-icon",
          viewBox: "0 0 42 38"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("title", { id: "site-logo" }, "Логотип Ридрес", -1),
          _createElementVNode("path", {
            d: "M4.497 37.375H3.122a2.82 2.82 0 0 1-1.943-.769 2.57 2.57 0 0 1-.805-1.856V7.187c0-.696.29-1.363.805-1.856a2.82 2.82 0 0 1 1.943-.769h1.375c.729 0 1.428.277 1.943.77.515.492.805 1.16.805 1.856V34.75c0 .696-.29 1.364-.805 1.856a2.82 2.82 0 0 1-1.943.769m15.116-24.937a2.57 2.57 0 0 0-.805-1.857 2.82 2.82 0 0 0-1.943-.768h-5.497c-.729 0-1.428.276-1.943.768a2.57 2.57 0 0 0-.805 1.857v2.296c0 .087.036.17.1.232a.35.35 0 0 0 .243.097H19.27a.35.35 0 0 0 .243-.097.32.32 0 0 0 .1-.232zM8.62 34.75c0 .696.29 1.364.805 1.856a2.82 2.82 0 0 0 1.943.769h5.497c.729 0 1.428-.277 1.943-.769s.805-1.16.805-1.856v-2.46a.16.16 0 0 0-.05-.117.18.18 0 0 0-.121-.048H8.792a.18.18 0 0 0-.122.048.16.16 0 0 0-.05.116zm10.822-17.062H8.792a.17.17 0 0 0-.172.164v11.484c0 .09.077.164.171.164h10.65a.17.17 0 0 0 .172-.164V17.852a.17.17 0 0 0-.171-.165zm7.043 19.687h-2.749a2.82 2.82 0 0 1-1.943-.769 2.57 2.57 0 0 1-.805-1.856V3.25c0-.696.29-1.364.805-1.856a2.82 2.82 0 0 1 1.943-.769h2.749c.729 0 1.428.277 1.943.769s.805 1.16.805 1.856v31.5c0 .696-.29 1.364-.805 1.856a2.82 2.82 0 0 1-1.943.769m15.106-2.835L38.823 6.65c-.127-1.284-1.455-2.215-2.966-2.076l-2.735.246c-1.511.137-2.633 1.289-2.506 2.572l2.768 27.891c.127 1.284 1.455 2.215 2.966 2.076l2.736-.246c1.51-.137 2.632-1.288 2.505-2.572z",
            fill: "#1B8DD0"
          }, null, -1)
        ]), 2)),
        _createElementVNode("span", {
          class: _normalizeClass(["site-logo__text", { 'site-logo__text--auth': _ctx.isAuth }])
        }, " Ридрес ", 2)
      ]),
      _: 1
    })).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}
}

})