import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "order-book-skeleton__cover-wrap flex-center" }
const _hoisted_2 = { class: "order-book-skeleton__cover book-cover book-cover--no-shadow flex-center-v" }
const _hoisted_3 = { class: "order-book-skeleton__wrap" }

import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderBookSkeleton',
  props: {
    withPrice: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    medium: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = _createElementVNode("div", {
      class: _normalizeClass(["order-book-skeleton", {
      'order-book-skeleton--small': _ctx.small,
      'order-book-skeleton--medium': _ctx.medium,
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(SkeletonLoader, { class: "order-book-skeleton__cover-img" })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(SkeletonLoader, { class: "order-book-skeleton__title" }),
        _createVNode(SkeletonLoader, { class: "order-book-skeleton__author" }),
        _createVNode(SkeletonLoader, { class: "order-book-skeleton__icon" }),
        (_ctx.withPrice)
          ? (_openBlock(), _createBlock(SkeletonLoader, {
              key: 0,
              class: "order-book-skeleton__price"
            }))
          : _createCommentVNode("", true)
      ])
    ], 2)).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}
}

})