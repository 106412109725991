import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "messages" }
const _hoisted_2 = { class: "messages__list reset-list hide-scroll" }
const _hoisted_3 = {
  key: 1,
  class: "messages__empty-msg"
}

import { computed, inject, onMounted } from 'vue';
import VMessage from './VMessage.vue';
import { useMessagesStore } from '@/stores/messages';
import { type Toast, toastKey } from '@/plugins/vue-toast-notification';

const LIMITED_MESSAGES_COUNT = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'VMessages',
  emits: ["close"],
  setup(__props) {

const { errorToast } = inject(toastKey) as Toast;



const messagesStore = useMessagesStore();

const limitedMessages = computed(
  () => messagesStore.messages?.slice(0, LIMITED_MESSAGES_COUNT) || [],
);

const moreMessagesCount = computed(() =>
  Math.max(messagesStore.count - LIMITED_MESSAGES_COUNT, 0),
);

onMounted(async () => {
  const [error] = await messagesStore.loadMessages();
  if (error) {
    errorToast('Не удалось загрузить сообщения');
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "messages__close-btn btn-close",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("span", { class: "visually-hidden" }, "Открыть/Закрыть меню", -1)
    ])),
    (_unref(messagesStore).messages?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(limitedMessages.value, (message) => {
              return (_openBlock(), _createElementBlock("li", {
                key: message.id,
                class: "messages__item"
              }, [
                _createVNode(VMessage, { message: message }, null, 8, ["message"])
              ]))
            }), 128))
          ]),
          (moreMessagesCount.value > 0)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/profile/messages",
                class: "messages__more-link",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Все (+" + _toDisplayString(moreMessagesCount.value) + ") ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, " Нет сообщений "))
  ]))
}
}

})