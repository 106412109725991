import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-list__item" }
const _hoisted_2 = { class: "nav-list__item-name" }

import SvgIcon from '@/components/SvgIcon.vue';
import type { NavListItem } from './items';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderNavItem',
  props: {
    url: {},
    name: {},
    icon: { default: null },
    iconWidth: { default: 25 },
    iconHeight: { default: 25 }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _cache[0] || (
      _setBlockTracking(-1),
      (_cache[0] = _createVNode(_component_router_link, {
        to: _ctx.url,
        class: "nav-list__link flex-center-v",
        onClick: ($event: any) => (emit('click'))
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(SvgIcon, {
                key: 0,
                icon: _ctx.icon,
                class: "nav-list__img",
                width: _ctx.iconWidth,
                height: _ctx.iconHeight
              }, null, 8, ["icon", "width", "height"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.name), 1)
          ])
        ]),
        _: 1
      }, 8, ["to", "onClick"])).cacheIndex = 0,
      _setBlockTracking(1),
      _cache[0]
    )
  ]))
}
}

})