import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { isHexColor, hexToRgb } from './utils';

type LoaderTypes = 'rectangle' | 'circle';

const BG_COLOR = '#82828233';
const SHIMMER_COLOR = '#ffffff';


export default /*@__PURE__*/_defineComponent({
  __name: 'SkeletonLoader',
  props: {
    width: { default: () => (undefined) },
    height: { default: () => (undefined) },
    type: { default: 'rectangle' },
    bgColor: { default: () => (BG_COLOR) },
    shimmerColor: { default: () => (SHIMMER_COLOR) }
  },
  setup(__props: any) {



const cssWidth = (() => {
  if (__props.width === undefined) {
    return undefined;
  }
  return __props.width + (!isNaN(+__props.width) ? 'px' : '');
})();

const cssHeight = (() => {
  if (__props.height === undefined) {
    return undefined;
  }
  return __props.height + (!isNaN(+__props.height) ? 'px' : '');
})();

const loaderClass = __props.type === 'circle' ? 'skeleton-loader--circle' : '';

const loaderStyles = (() => ({
  width: cssWidth,
  height: cssHeight,
  backgroundColor: __props.bgColor,
}))();

const shimmerStyles = (() => {
  const rgb = isHexColor(__props.shimmerColor) ? hexToRgb(__props.shimmerColor) : SHIMMER_COLOR;

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
  };
})();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["skeleton-loader", _unref(loaderClass)]),
    style: _normalizeStyle(_unref(loaderStyles))
  }, [
    _createElementVNode("div", {
      class: "skeleton-loader__shimmer",
      style: _normalizeStyle(_unref(shimmerStyles))
    }, null, 4),
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})