import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["datetime"]
const _hoisted_2 = ["innerHTML"]

import { computed } from 'vue';
import { useSanitize } from '@/composables/useSanitize';
import { useDate } from '@/composables/useDate';
import type { Message } from '@/stores/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'VMessage',
  props: {
    message: {},
    bordered: { type: Boolean, default: false }
  },
  setup(__props: any) {



const {
  isToday,
  formatMachineDateTime,
  isYesterdayOrAfter,
  formatRelativeDateTime,
  formatHumanDateTime,
} = useDate();

const { sanitizeHtml } = useSanitize();

const customClass = computed(() => ({
  'message--active': isToday(__props.message.createdAt),
  'message--bordered': __props.bordered,
}));

const machineDateTime = computed(() =>
  formatMachineDateTime(__props.message.createdAt),
);

const humanDateTime = computed(() => {
  const { createdAt } = __props.message;

  if (isYesterdayOrAfter(createdAt)) {
    return formatRelativeDateTime(createdAt);
  }

  return formatHumanDateTime(createdAt);
});

const messageSafeHtml = computed(() => sanitizeHtml(__props.message.html));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", customClass.value])
  }, [
    _createElementVNode("time", {
      datetime: machineDateTime.value,
      class: "message__date"
    }, _toDisplayString(humanDateTime.value), 9, _hoisted_1),
    _createElementVNode("p", {
      class: "message__text",
      innerHTML: messageSafeHtml.value
    }, null, 8, _hoisted_2)
  ], 2))
}
}

})